<template>
  <div class="cy-infra-import-zero-state">
    <img
      :src="$static.zeroStateImg"
      :class="['cy-infra-import-zero-state__logo', {
        'cy-infra-import-zero-state__logo--disabled': !hasCatalogRepositoriesWithCredentials || !hasCredentials,
      }]"
      :alt="$t('zeroStateImg')">

    <div class="cy-infra-import-zero-state__header">
      <div class="d-inline-flex">
        <h4 class="cy-infra-import-zero-state__header-title">
          {{ $t('untranslated.infraImport') }}
        </h4>
      </div>

      <p class="cy-infra-import-zero-state__header-description">
        <span v-if="!hasCatalogRepositoriesWithCredentials || !hasCredentials">
          {{ $t('infraImportMissingDependenciesHeader') }}
        </span>
        <template v-else>
          <span>
            {{ $t('infraImportDependenciesHeader') }}
          </span>
          <a
            class="cy-link"
            :href="$docLinks.infraImport.index"
            target="_blank">
            {{ $t('theDocs') }}
          </a>
        </template>
      </p>
    </div>

    <div
      v-if="hasCatalogRepositoriesWithCredentials && hasCredentials"
      class="cy-infra-import-zero-state__actions">
      <CyButton
        data-cy="create-stack-btn"
        class="mr-5"
        variant="secondary"
        @click="SET_WIZARD_CONFIG({ createStackOnly: true, active: true })">
        {{ $t('createStack') }}
      </CyButton>

      <CyTooltip
        content-class="create-stack-and-project-tooltip"
        nudge-bottom="10"
        bottom>
        <template #activator="{ on }">
          <span v-on="on">
            <CyButton
              :disabled="!hasConfigRepositoriesWithCredentials"
              @click="SET_WIZARD_CONFIG({ createStackOnly: false, active: true })">
              {{ $t('createStackAndProject') }}
            </CyButton>
          </span>
        </template>
        <span class="tooltip-hint">
          {{ $t('createStackAndProjectHint') }}
        </span>
      </CyTooltip>
    </div>

    <v-list
      v-else
      two-line
      class="cy-infra-import-zero-state__dependencies elevation-1">
      <v-subheader class="cy-infra-import-zero-state__dependencies-title">
        {{ $t('unlockFeature') }}
      </v-subheader>
      <v-progress-linear
        class="cy-infra-import-zero-state__dependencies-progress-bar"
        :value="dependenciesProgress"/>
      <v-list-item
        v-for="{ title, subtitle, hasAvailable, routeName } of dependenciesList"
        :key="title"
        height="36"
        @click="$router.push({ name: routeName })">
        <v-list-item-content>
          <v-list-item-title>
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon
            size="16"
            :color="hasAvailable ? 'success' : 'error'">
            {{ hasAvailable ? 'check' : 'close' }}
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { infraImport } from '@/assets/images'
import { supportedProviders } from '@/store/modules/organization/infraImport'

export default {
  name: 'CyInfraImportZeroState',
  computed: {
    ...mapState('organization', {
      catalogRepositories: (state) => state.available.catalogRepositories,
      configRepositories: (state) => state.available.configRepositories,
      credentials: (state) => state.available.credentials,
    }),
    $static () {
      return {
        zeroStateImg: infraImport.zeroState,
      }
    },
    hasCredentials () {
      const { credentials = [] } = this
      return !_.$isEmpty(credentials.filter(({ type: credentialType }) => supportedProviders.includes(credentialType)))
    },
    hasConfigRepositoriesWithCredentials () {
      const { configRepositories = [] } = this
      return !_.$isEmpty(configRepositories.filter((configRepo) => _.has(configRepo, 'credential_canonical')))
    },
    hasCatalogRepositoriesWithCredentials () {
      const { catalogRepositories = [] } = this
      return !_.$isEmpty(catalogRepositories.filter((configRepo) => _.has(configRepo, 'credential_canonical')))
    },
    dependenciesList () {
      const { hasCredentials, hasCatalogRepositoriesWithCredentials, hasConfigRepositoriesWithCredentials } = this

      return [
        {
          title: this.$t('providerCredentials'),
          subtitle: this.$t('providerCredentialsHint'),
          hasAvailable: hasCredentials,
          routeName: 'newCredential',
        },
        {
          title: this.$t('routes.catalogRepository'),
          subtitle: this.$t('catalogRepositoryHint'),
          hasAvailable: hasCatalogRepositoriesWithCredentials,
          routeName: 'newCatalogRepository',
        },
        {
          title: this.$t('routes.configRepository'),
          subtitle: this.$t('configRepositoryHint'),
          hasAvailable: hasConfigRepositoriesWithCredentials,
          routeName: 'newConfigRepository',
        },
      ]
    },
    countOfMissingDependencies () {
      const { hasConfigRepositoriesWithCredentials, hasCatalogRepositoriesWithCredentials, hasCredentials } = this

      return _.filter(
        [hasConfigRepositoriesWithCredentials, hasCatalogRepositoriesWithCredentials, hasCredentials],
        (item) => !item,
      ).length
    },
    dependenciesProgress () {
      return {
        0: '100',
        1: '66.6',
        2: '33.3',
        3: '0',
      }[this.countOfMissingDependencies]
    },
  },
  methods: {
    ...mapMutations('organization/infraImport', [
      'SET_WIZARD_CONFIG',
    ]),
  },
  i18n: {
    messages: {
      en: {
        catalogRepositoryHint: 'To store the created Stack',
        configRepositoryHint: 'To store the project configuration',
        createStack: 'Create Stack',
        createStackAndProject: 'Create Stack & Project',
        createStackAndProjectHint: 'You will need to create a Config Repository to be able to use this feature.',
        infraImportDependenciesHeader: '@:InfraImport lets you import your infrastructure resources and create a stack based on them. You can create a Stack or a Stack/Project combination. Learn more about @:InfraImport by reading',
        infraImportMissingDependenciesHeader: '@:InfraImport allows to create Stacks based on the exisiting cloud infrastructure. It requires the following resources:',
        providerCredentials: 'Provider Credentials',
        providerCredentialsHint: 'Enable connection to the cloud provider API',
        theDocs: 'the docs.',
        unlockFeature: 'Add to unlock the feature:',
        zeroStateImg: 'Zero state logo',
      },
      es: {
        catalogRepositoryHint: 'Para almacenar el stack creada',
        configRepositoryHint: 'Para almacenar la configuración del proyecto',
        createStack: 'Crear stack',
        createStackAndProject: 'Crear stack & proyecto',
        createStackAndProjectHint: 'Deberá crear un repositorio de configuración para poder utilizar esta función.',
        infraImportDependenciesHeader: '@:InfraImport le permite importar sus recursos de infraestructura y crear un stack basado en ellos. Puede crear un stack o una combinación de stack/proyecto. Obtenga más información sobre @:InfraImport leyendo',
        infraImportMissingDependenciesHeader: '@:InfraImport permite crear Stacks basados en la infraestructura de nube existente. Requiere los siguientes recursos:',
        providerCredentials: 'Credenciales de proveedor',
        providerCredentialsHint: 'Habilite la conexión a la API del proveedor de la nube',
        theDocs: 'los docs.',
        unlockFeature: 'Agregar para desbloquear la función:',
        zeroStateImg: 'Logotipo de estado cero',
      },
      fr: {
        catalogRepositoryHint: 'Pour stocker la stack créée',
        configRepositoryHint: 'Pour stocker la configuration du projet',
        createStack: 'Créer une stack',
        createStackAndProject: 'Créer une stack & un projet',
        createStackAndProjectHint: 'Vous devrez créer un référentiel de configuration pour pouvoir utiliser cette fonctionnalité.',
        infraImportDependenciesHeader: `@:InfraImport vous permet d'importer vos ressources d'infrastructure et de créer une stack basée sur celles-ci. Vous pouvez créer une stack ou une combinaison stack/projet. En savoir plus sur @:InfraImport en lisant`,
        infraImportMissingDependenciesHeader: `@:InfraImport permet de créer des Stacks basés sur l'infrastructure cloud existante. Il nécessite les ressources suivantes:`,
        providerCredentials: 'Identifiants du fournisseur',
        providerCredentialsHint: `Activer la connexion à l'API du fournisseur de cloud`,
        theDocs: 'les docs.',
        unlockFeature: 'Ajouter pour déverrouiller la fonctionnalité:',
        zeroStateImg: `Logo de l'état zéro`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.create-stack-and-project-tooltip {
  max-width: 146px;
  margin-top: 8px;
  padding: 5px 8px;
  background-color: map.get($slate-grey, "main") !important;
  text-align: left;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cy-infra-import-zero-state {
  @extend .centered;

  &__logo {
    width: 400px;
    height: 400px;
  }

  &__logo--disabled {
    filter: grayscale(1);
  }

  &__header {
    @extend .centered;

    max-width: 585px;
    max-height: 123px;
    margin-top: 17px;
    text-align: center;
  }

  &__header-title {
    color: cy-get-color("primary", "main");
    font-size: $font-size-lg;
    font-weight: $font-weight-default;
  }

  &__header-description {
    margin-top: 12px;
    color: cy-get-color("grey", "dark-2");
  }

  &__dependencies {
    width: 284px;
    padding: 0 0 8px;
    border-radius: 10px;
  }

  &__dependencies-title {
    @extend .centered;

    height: 40px;
    color: map.get($slate-grey, "dark-1");
    font-size: $font-size-default;
  }

  ::v-deep .v-progress-linear__determinate {
    height: 8px !important;
    border-color: cy-get-color("secondary") !important;
    background-color: cy-get-color("secondary") !important;
  }

  ::v-deep .v-list-item {
    min-height: 44px;

    &__content {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__title {
      margin-bottom: 0;
      color: map.get($slate-grey, "dark-1");
      font-size: $font-size-default !important;
      line-height: 21px;
    }

    &__subtitle {
      color: cy-get-color("grey", "dark-2");
      font-size: $font-size-xs !important;
      line-height: 15px;
    }

    &__icon {
      align-self: center;
      margin: 0;
    }
  }
}
</style>
