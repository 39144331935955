var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cy-infra-import-zero-state"},[_c('img',{class:['cy-infra-import-zero-state__logo', {
      'cy-infra-import-zero-state__logo--disabled': !_vm.hasCatalogRepositoriesWithCredentials || !_vm.hasCredentials,
    }],attrs:{"src":_vm.$static.zeroStateImg,"alt":_vm.$t('zeroStateImg')}}),_c('div',{staticClass:"cy-infra-import-zero-state__header"},[_c('div',{staticClass:"d-inline-flex"},[_c('h4',{staticClass:"cy-infra-import-zero-state__header-title"},[_vm._v(" "+_vm._s(_vm.$t('untranslated.infraImport'))+" ")])]),_c('p',{staticClass:"cy-infra-import-zero-state__header-description"},[(!_vm.hasCatalogRepositoriesWithCredentials || !_vm.hasCredentials)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('infraImportMissingDependenciesHeader'))+" ")]):[_c('span',[_vm._v(" "+_vm._s(_vm.$t('infraImportDependenciesHeader'))+" ")]),_c('a',{staticClass:"cy-link",attrs:{"href":_vm.$docLinks.infraImport.index,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('theDocs'))+" ")])]],2)]),(_vm.hasCatalogRepositoriesWithCredentials && _vm.hasCredentials)?_c('div',{staticClass:"cy-infra-import-zero-state__actions"},[_c('CyButton',{staticClass:"mr-5",attrs:{"data-cy":"create-stack-btn","variant":"secondary"},on:{"click":function($event){return _vm.SET_WIZARD_CONFIG({ createStackOnly: true, active: true })}}},[_vm._v(" "+_vm._s(_vm.$t('createStack'))+" ")]),_c('CyTooltip',{attrs:{"content-class":"create-stack-and-project-tooltip","nudge-bottom":"10","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_c('CyButton',{attrs:{"disabled":!_vm.hasConfigRepositoriesWithCredentials},on:{"click":function($event){return _vm.SET_WIZARD_CONFIG({ createStackOnly: false, active: true })}}},[_vm._v(" "+_vm._s(_vm.$t('createStackAndProject'))+" ")])],1)]}}],null,false,3261234564)},[_c('span',{staticClass:"tooltip-hint"},[_vm._v(" "+_vm._s(_vm.$t('createStackAndProjectHint'))+" ")])])],1):_c('v-list',{staticClass:"cy-infra-import-zero-state__dependencies elevation-1",attrs:{"two-line":""}},[_c('v-subheader',{staticClass:"cy-infra-import-zero-state__dependencies-title"},[_vm._v(" "+_vm._s(_vm.$t('unlockFeature'))+" ")]),_c('v-progress-linear',{staticClass:"cy-infra-import-zero-state__dependencies-progress-bar",attrs:{"value":_vm.dependenciesProgress}}),_vm._l((_vm.dependenciesList),function(ref){
    var title = ref.title;
    var subtitle = ref.subtitle;
    var hasAvailable = ref.hasAvailable;
    var routeName = ref.routeName;
return _c('v-list-item',{key:title,attrs:{"height":"36"},on:{"click":function($event){return _vm.$router.push({ name: routeName })}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(title)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(subtitle)+" ")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"16","color":hasAvailable ? 'success' : 'error'}},[_vm._v(" "+_vm._s(hasAvailable ? 'check' : 'close')+" ")])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }